import React from 'react';
import { Popover, Checkbox } from 'antd';
import { observer } from 'mobx-react';

const InputAfter = Loader.loadBaseComponent('InputAfter');
const VillageMesDetail = Loader.loadBusinessComponent('VillageMesDetail');

@Decorator.businessProvider('flowPerson', 'residentPerson')
@observer
class VillageResource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectIf: true,
      choseId: undefined,
      show: false,
      villageList: [],
      value: '',
      passVillageList: [],
      otherCommunityData: []
    };
    this.communityScroll = React.createRef();
    this.requestVillageData();
    this.i = 0;
  }
  /**获取社区及各种统计数据 */
  requestVillageData = () => {
    Service.community.statisticsList({ limit: 1000, offset: 0 }).then(res => {
      this.setState({
        villageList: res.list,
        passVillageList: res.list
      });
      let arr = [];
      res.list.map(v => arr.push(v.id));
      if (arr.length == 0) {
        return;
      }
      Promise.all([
        Utils.catchPromise(Service.community.countVillagePeople({ villageIds: arr })),
        Utils.catchPromise(Service.community.countVillageDevice({ villageIds: arr })),
        Utils.catchPromise(Service.community.countFace({ villageIds: arr, days: 7 }))
      ]).then(res => {
        const [peopleRes, deviceRes, faceRes] = res;
        peopleRes.forEach(item => {
          deviceRes.forEach(v => {
            if (v.id === item.id) {
              v = Object.assign(v, item);
            }
          });
        });
        faceRes.forEach(item => {
          deviceRes.forEach(v => {
            if (v.id === item.villageId) {
              v = Object.assign(v, item);
            }
          });
        });
        this.setState({
          otherCommunityData: deviceRes
        });
      });
    });
  };
  /**选中某个小区 */
  handleVillageSelect = id => {
    const { residentPerson, type, flowPerson } = this.props;
    const { passVillageList, choseId } = this.state;
    this.props.handleSelctId(true);
    if (type == 'registered') {
      residentPerson.editSearchData({ villageIds: [id], offset: 0 }, 'all');
    } else {
      flowPerson.editSearchData({ villageIds: [id], offset: 0 }, 'all');
    }
    /**点击选中的社区，全选按钮选中 */
    if (id == choseId) {
      this.setState({
        choseId: undefined,
        selectIf: true
      });
      if (type == 'registered') {
        residentPerson.editSearchData({ villageIds: [], offset: 0 }, 'all');
      } else {
        flowPerson.editSearchData({ villageIds: [], offset: 0 }, 'all');
      }
      this.props.FreShen(false,true);
      return;
    }
    this.props.FreShen(false,true);
    if (passVillageList.length > 1) {
      this.setState({
        selectIf: false
      });
    } else {
      this.setState({
        selectIf: true
      });
    }
    this.setState({
      choseId: id,
      show: true
    });
    setTimeout(() => {
      this.mergeSearchData();
    }, 100);
  };
  /**条件搜索社区 */
  handleVillageInputSearch = e => {
    clearTimeout(this.SearchVillage);
    const { residentPerson, flowPerson, type, activeKey } = this.props;
    const { passVillageList } = this.state;
    this.setState({
      value: e.target.value
    });
    let keywords = e.target.value;
    /**重置搜索条件 */
    this.props.handleSelctId();
    this.SearchVillage = setTimeout(() => {
      Service.community
        .statisticsList({
          limit: 1000,
          offset: 0,
          keywords
        })
        .then(res => {
          let selectIf = false;
          if (res.list.length == passVillageList.length) {
            selectIf = true;
          }
          this.setState({
            selectIf,
            choseId: undefined,
            villageList: res.list
          });
          if (res.list.length == 0) {
            this.props.HandleNoVillageData();
            return;
          }
          if (keywords.length == 0) {
            this.setState({
              selectIf: true
            });
          }
          /**初始化搜素条件，并将id放入数组中 */
          if (type == 'registered') {
            residentPerson.editSearchData({ villageIds: res.list.map(v => v.id), offset: 0 }, 'all');
          } else {
            flowPerson.editSearchData({ villageIds: res.list.map(v => v.id), offset: 0 }, 'all');
          }
          this.props.FreShen(false,true);
        });
    }, 500);
  };
  handleSelect = e => {
    const { selectIf } = this.state;
    const { residentPerson, type, flowPerson, activeKey } = this.props;
    this.props.handleSelctId(true);
    if (type == 'registered') {
      residentPerson.editSearchData({ villageIds: [], offset: 0 }, 'all');
    } else {
      flowPerson.editSearchData({ villageIds: [], offset: 0 }, 'all');
    }
    this.setState({
      selectIf: true,
      choseId: undefined
    });
    this.requestVillageData();
    if (!selectIf) {
      setTimeout(() => {
        this.mergeSearchData();
      }, 100);
      this.props.FreShen(false,true);
      this.setState({
        value: ''
      });
    }
  };
  /**控制社区总览跳转小区选中的问题 */
  componentWillReceiveProps(nextProps) {
    if (nextProps.choseId && nextProps.selectIf == false) {
      if (this.i < 1) {
        this.setState({
          choseId: nextProps.choseId,
          selectIf: nextProps.selectIf
        });
      }
      this.i = this.i + 1;
    }
  }
  onCancel = () => {
    this.setState({
      value: '',
      selectIf: true
    });
    this.requestVillageData();
    this.props.FreShen(false,true);
  };
  mergeSearchData = () => {
    let { activeKey } = this.props;
    const id = Utils.uuid();
    this.props.mergeSearchData(activeKey, id);
  };
  render() {
    const { selectIf, choseId, villageList, otherCommunityData, value } = this.state;
    const { type } = this.props;
    return (
      <React.Fragment>
        <div className="community-title-real" style={{ margin: 0 }}>
          <div>{type === 'registered' ? '已登记人员管理' : '未登记人员管理'}</div>
          <div className="community-checkbox">
            全部显示
            <span style={{ paddingLeft: '6px' }}>
              <Popover
                overlayClassName={'checkbox-span-pop-community'}
                placement="bottom"
                content={
                  selectIf ? (
                    <span>{type == 'registered' ? '请选择下面列表查看单个小区登记人口' : '请选择下面列表查看单个小区未登记人口'}</span>
                  ) : (
                    <span>{type == 'registered' ? '全部显示小区登记人口' : '全部显示小区未登记人口'}</span>
                  )
                }>
                <Checkbox onChange={this.handleSelect} checked={selectIf} />
              </Popover>
            </span>
          </div>
        </div>
        <div className="community-input">
          <InputAfter
            placeholder="请输入小区名称搜索"
            size={'lg'}
            style={{ color: 'rgba(0,0,0,.25)' }}
            value={value}
            onChange={this.handleVillageInputSearch}
            onCancel={this.onCancel}
          />
        </div>
        <div className="community-exp" ref={this.communityScroll}>
          {villageList.map((v, index) => (
            <VillageMesDetail
              type={type}
              key={index}
              data={v}
              otherData={otherCommunityData.filter(a => a.id == v.id)}
              choseId={choseId}
              handleVillageSelect={this.handleVillageSelect}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default VillageResource;
