(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("mobxReact"));
	else if(typeof define === 'function' && define.amd)
		define("VillageResourceList", ["React", "antd", "mobxReact"], factory);
	else if(typeof exports === 'object')
		exports["VillageResourceList"] = factory(require("React"), require("antd"), require("mobxReact"));
	else
		root["VillageResourceList"] = factory(root["React"], root["antd"], root["mobxReact"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 